import { environment } from './../../../environments/environment';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { PlatformService } from '../services/platform.service';
import {devDomain} from '../../../../config/dev.domain';

const HOST = environment.error_host + '/error/handle';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  /**
   * List of app hosts from which request urls should not be modified
   *
   * @private
   */
  private _ignoredHosts = [
    'https://localhost:4200',
    'https://stage.wildfortune.com',
    'https://dev.wildfortune.com',
    'https://stage.wild-fortune.com',
    'https://wildfortune.com',
    ...devDomain
  ];

  constructor(
    private _platform: PlatformService,
    private _http: HttpClient,
  ) { }

  /**
   * Handle all errors in application
   *
   * @param error
   */
  handleError(error: any) {
    if (!this._platform.isBrowser) {
      return;
    }
    const chunkFailedMessage1 = /Loading chunk [\d]+ failed/;
    const chunkFailedMessage2 = /Failed to fetch dynamically imported module/;
    if (chunkFailedMessage1.test(error.message) || chunkFailedMessage2.test(error.message)) {
      this._clearCacheWorker();
    }

    delete error['ngDebugContext'];
    delete error['ngErrorLogger'];

    if (error.rejection) {
      error = error.rejection;
    }

    switch (true) {
      case error instanceof HttpErrorResponse:
        this._sendError({
          ...error,
          message: `Failed HTTP request for url: ${(error as HttpErrorResponse).url}`
        });
        break;
      default:
        if (!error.type) {
          error.type = 'frontend';
        }

        console.log(error);

        try {
          this._sendError({
            ...error,
            message: error.message || (error as Error).stack || 'Unknown message'
          });
        } catch (e) {
          this._sendError({
            name: error.name,
            message: error.message || (error as Error).stack || 'Unknown message'
          });
        }
        break;
    }
  }


  /**
   * Send error to backend
   *
   * @param error
   * @private
   */
  private _sendError(error: Error) {
    if (!navigator.onLine) {
      return;
    }

    try {
      this._http.post(this._resolveHost(), {
        ts: Date.now(),
        userAgent: this._getUserAgent(),
        ...error
      }).subscribe();
    } catch (e) {}
  }

  /**
   * Returns object containing information about user-agent and internet connection of user
   *
   * @private
   */
  private _getUserAgent() {
    const nav: any = navigator;
    return {
      location: window.location.href,
      language: nav.language || 'unknown',
      platform: nav.platform || 'unknown',
      userAgent: nav.userAgent || 'unknown',
      connectionDownlink: nav.connection ? nav.connection.downlink : 'unknown',
      connectionEffectiveType: nav.connection ? nav.connection.effectiveType : 'unknown',
    };
  }

  /**
   * Resolve host for mirrors
   * @private
   */
  private _resolveHost() {
    if (this._platform.isBrowser) {
      const host = window.location.host;
      const hostName = host.split('.')[1];

      return !this._ignoredHosts.some(item => item.includes(host)) ?
        HOST.replace('wildfortune', hostName) :
        HOST;
    } else {
      return HOST;
    }
  }
  private async _clearCacheWorker() {
    if ('serviceWorker' in navigator && 'caches' in window) {
      await navigator.serviceWorker.getRegistrations()
          .then(registrations => {
            for (let registration of registrations) {
              if (!registration.scope.includes('onesignal')) {
                registration.unregister()
                    .then(success => {
                      console.log('Unregister old worker - ' + success);
                    })
                    .catch(error => {
                      console.log(error)
                    });
              }
            }
          })
          .catch(error => {
            console.log(error)
          });
      await caches.keys()
          .then(cacheNameList => {
            cacheNameList.forEach(cacheName => {
              caches.delete(cacheName);
            });
          })
          .catch(error => {
            console.log(error);
          });
      setTimeout(() => {
        const paramType = window.location.href.indexOf('?') !== -1 ? '&' : '?';
        const newUrl = window.location.href + paramType + 'nocache=' + Date.now();
        window.location.href = newUrl;}, 500);
    }
  }

}
