import { Injectable } from '@angular/core';
import { FpjsClient, defaultEndpoint } from '@fingerprintjs/fingerprintjs-pro-spa';
import { environment } from 'src/environments/environment';
import { PlatformService } from './platform.service';
import { UserService } from './user/user.service';
import { filter, first } from 'rxjs/operators';
import { WindowService } from './window.service';
import { CookieService } from 'ngx-unificator/services';

@Injectable({
  providedIn: 'root'
})
export class FingerPrintService {

  private _fingerPrintJsClient: FpjsClient;
  private _fingerPrintJsClientInitPromise: Promise<Object>;
  private _clientOptions = {
    loadOptions: {
      apiKey: environment.fingerprint,
      endpoint: [
        `${this._window.nativeWindow.location.origin?.includes('localhost') ? environment.app_host : this._window.nativeWindow.location.origin}/tnAoHKRfBhcwDWIZ/wKIFsitegCiRc3z9?region=eu`,
        defaultEndpoint
      ],
    }
  };

  public visitorId = null;
  public visitorIdResult = null;

  constructor(
    private _platform: PlatformService,
    private _user: UserService,
    private _window: WindowService,
    private _cookie: CookieService
  ) {
    this._init();
  }

  private _init() {
    if (this._platform.isBrowser) {
      this._fingerPrintJsClient = new FpjsClient(this._clientOptions);
      this._fingerPrintJsClientInitPromise = this._fingerPrintJsClient?.init();
    }
  }

  /**
   * The function `getVisitorData` retrieves visitor data using the FingerPrint.js client if the platform
   * is a browser and the user is authenticated.
   */
  public getVisitorDataBrand() {
    if (this._platform.isBrowser && !this._cookie.check('runAutoTest')) {
      if (this._cookie.check('fingerPrint')) {
        return;
      }
      this._user.auth$.pipe(
        filter((auth) => !!auth),
        first()
        ).subscribe(async () => {
          await this._fingerPrintJsClientInitPromise;
          // for param tag use 'site' - brand name
          // for param tag linkedId use user id
          // integrations for service https://app.segment.com/
          const title = this._window?.nativeWindow?.location?.href.split('/')[this._window?.nativeWindow?.location?.href?.split('/').length - 1];
          const data = await this._fingerPrintJsClient?.getVisitorData({
            linkedId: this._user?.info?.id,
            tag: {
              site: 'wildfortune.com',
              integrations: {
                segment: {
                  skipIntegration: false,
                  page: {
                    name: `${this._window.nativeWindow.location.host} - ${title}`,
                    properties: {
                      linkedId: this._user?.info?.id,
                      url: this._window.nativeWindow.location.href,
                      title: `${this._window.nativeWindow.location.host} - ${title}`
                    }
                  }
                }
              }
            },
          });
          this.visitorId = data?.visitorId;
          this.visitorIdResult = data;
          this._cookie.set('fingerPrint', data?.visitorId, 999, '/', (window as any).location.hostname);
      });
    }
  }

  /**
   * Clears visitor data from cache regardless of the cache implementation
   */
  public clearCache() {
    return this._platform.isBrowser ? this._fingerPrintJsClient?.clearCache() : null;
  }

}
